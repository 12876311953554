export const SEATED_API = window.location.hostname === "seated-merchant.firebaseapp.com" || window.location.hostname === "seated-merchant.web.app" || window.location.hostname === "seatedrestaurants.io" || window.location.hostname === "www.seatedrestaurants.io"
                          ? "https://merchant-portal.seatedapp.io" :
                          window.location.hostname === 'seated-merchant-staging.firebaseapp.com' || window.location.hostname === 'seated-merchant-staging.web.app' ?
                          "https://merchant-portal.staging.seatedapp.io" :
                          "https://merchant-portal.uat.seatedapp.io";


function* dashboardRequest(data) {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/home",
      method: "POST",
      params: data,
      body: {
        businessIds: Boolean(data.business_id) ? [data.business_id] : [],
      }
    });
    return yield response.json();
  } catch (e) {
    throw e
  }
}

function* getStatements(data) {
  try {
    const response = yield makeRequest({
      path: "v1/merchant-portal/history",
      method: "POST",
      params: data,
      body: {
        businessIds: Boolean(data.business_ids) ? [data.business_ids] : [],
      }
    });
    return yield response.json();
  } catch (e) {
    throw e
  }
}

function* statementsXls(data) {
  try {
    const response = yield makeDownloadRequest({
      path: "v1/merchant-portal/history/SeatedStatements.xlsx",
      method: "POST",
      params: data,
      body: {
        businessIds: Boolean(data.business_ids) ? [data.business_ids] : [],
      },
      filename: "SeatedStatements.xlsx"
    });
    return yield response.blob();
  } catch (e) {
    throw e
  }
}

function* updateBusinessAccountsV2() {
  
  try {
    const response = yield makeRequest({
      path: `v1/merchant-portal/business/account/accept`,
      method: "PATCH",
      body: {}
    })
    return yield response.json()
  }catch(e){
    console.log(e);
    throw  e.messages;
  }
}

function* getBusinessAccountsV2(data) {
  try {
    const response = yield makeRequest({
      path: `v1/merchant-portal/business/account`,
      method: "GET"
    })
    return yield response.json()
  }catch(e){
    console.log("Error", e)
    throw  e.messages;
  }
}

function* getBillPayments(data){
  try {
    const response = yield makeRequest({
      path:"v1/merchant-portal/payments",
      method:"POST",
      params: data,
      body: {
        businessIds: Boolean(data.business_ids) ? [data.business_ids] : [],
      }
    })
    return yield response.json()
  } catch (error) {
    throw error
  }
}

function* getUnpaidBalance(data){
  try {
    const response = yield makeRequest({
      path:"v1/merchant-portal/unpaid-balances",
      method:"POST",
      body: {
        businessIds: Boolean(data.business_ids) ? [data.business_ids] : [],
      }
    })
    return yield response.json()
  } catch (error) {
    throw error
  }
}

export function makeRequest({ path, method, params, body }) {
  let url = new URL(`${SEATED_API}/${path}`);
  if (params)
    Object.keys(params).forEach(key => {
      if(params[key]!==null){
        return  url.searchParams.append(key, params[key])
      }
     }
    );
  return fetch(url, {
    method,
    credentials: 'include',  // Include cookies in the request
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  }).then(res => {
      return errorHandler(res)
    })
}

export function makeDownloadRequest({ path, method, params, body, filename }) {
  let url = new URL(`${SEATED_API}/${path}`);
  
  if (params)
    Object.keys(params).forEach(key => {
      if(params[key]!==null){
        return  url.searchParams.append(key, params[key])
      }
     }
    );
  return fetch(url, {
    method,
    credentials: 'include',  // Include cookies in the request
    headers: {
      "Content-disposition" : "attachment; filename=" + filename,
      "Content-Type": "application/vnd.ms-excel"
    },
    body: JSON.stringify(body)
  }).then(res => {
      return errorHandler(res)
    })
}

function errorHandler(response) {
  return new Promise((resolve,reject)=>{
      if(response.status === 200 || response.status === 201){
        resolve(response) 
      } else if(response.status === 401){
        response.json().then(r => {
          reject(response.status)
          window.localStorage.removeItem("auth_token_verified")
          window.location.reload()
        }).catch((e)=>console.log(e))
      }
      else {
        response.json().then(r=>{
          reject(r)
        }).catch((e)=>console.log(e))
      }
  })
}
export const ApiV2 = {
  dashboardRequest,
  getStatements,
  statementsXls,
  getBusinessAccountsV2,
  updateBusinessAccountsV2,
  getUnpaidBalance,
  getBillPayments: getBillPayments
};
